import { Page } from "../Components";

export const Partners = () => {
  return (
    <Page title="ПАРТНЕРЫ" className="h-screen">
      <div className="relative flex items-center">
        <div className="flex flex-col xl:flex-row justify-around items-center gap-32 pt-8 xl:pt-24 w-full">
          <a
            href="https://vitrulux.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="w-4/5 xl:w-[380px] h-auto xl:h-16"
              src="img/vitrulux/logo.svg"
              alt=""
            />
          </a>
          <a
            href="https://keosvet.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="w-4/5 xl:w-[380px] h-auto xl:h-32"
              src="img/keosvet/logo.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </Page>
  );
};
