import { Link } from "react-router-dom";
import { GoldenText } from "./GoldenText";

interface SecondaryButtonProps {
  value: string;
  type?: "button" | "submit";
  className?: string;
  onClick?: () => void;
}

export const SecondaryButton = ({ value, className }: SecondaryButtonProps) => {
  return (
    <div
      className={`${className} w-full h-12 p-[1px] bg-gradient-to-br via-20% to-50% rounded-lg from-yellow-200 via-yellow-600 hover:bg-gradient-to-tl`}
    >
      <div className="w-full h-full from-40% p-[1px] rounded-lg bg-gradient-to-br from-black to-lightblue hover:bg-gradient-to-tl">
        <div className="w-full bg-black h-full rounded-lg">
          <div className="text-xl w-full h-full flex items-center justify-center rounded-lg">
            <GoldenText>{value}</GoldenText>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConsultationLink = () => {
  return (
    <Link className="w-64" to="/консультация">
      <SecondaryButton value="КОНСУЛЬТАЦИЯ" />
    </Link>
  );
};
