import { useEffect, useState } from "react";
import { Page, SecondaryButton } from "../../Components";
import { GoldenText } from "../../Components/GoldenText";
import { PrimaryButton } from "../../Components/RequestButton";
import { IProject, Project } from "./Project";
import { ProjectModal } from "./ProjectModal";
import { useSwipeable } from "react-swipeable";

type IProjects = "VITRULUX" | "KEOSVET";

const VITRULUX_PROJECTS = [
  {
    imageId: "luzniki",
    name: '"Лужники"',
    location: "г. Москва",
  },
  {
    imageId: "zaradje",
    name: 'Парк "Зарядье"',
    location: "г. Москва",
  },
  {
    imageId: "dworcowaja",
    name: "Дворцовая площадь",
    location: "г. Санкт-Петербург",
  },
  {
    imageId: "zoo",
    name: 'Бизнес-центр "Зоологический"',
    location: "г. Москва",
  },
  {
    imageId: "smolnyj",
    name: 'ЖК "Смольный парк"',
    location: "г. Москва",
  },
  {
    imageId: "langenzipen",
    name: 'Бизнес-центр "Langenzipen"',
    location: "г. Санкт-Петербург",
  },
];

const KEOSVET_PROJECTS = [
  {
    imageId: "mailru",
    name: "Офис MAIL.RU",
    location: "г. Воронеж",
  },
  {
    imageId: "office",
    name: "Офисное освещение",
    location: "г. Подольск",
  },
  {
    imageId: "mirovaja-tehnika",
    name: 'Завод "Мировая техника"',
    location: "г. Воронеж",
  },
  {
    imageId: "sotowaja-sviaz",
    name: "Магазин сотовой связи",
    location: "г. Челябинск",
  },
  {
    imageId: "liniejnyje",
    name: "Освещение линейными светильниками",
    location: undefined,
  },
  {
    imageId: "fasadnoje",
    name: "Фасадное освещение",
    location: undefined,
  },
];

export const Projects = () => {
  const [activeTab, setActiveTab] = useState<"VITRULUX" | "KEOSVET">(
    "VITRULUX"
  );

  const handleTabClick = (activeTab: IProjects) => {
    setActiveTab(activeTab);
  };

  return (
    <Page title="ПРОЕКТЫ" className="h-fit">
      <div className="flex flex-col gap-4 pb-16">
        <div className="relative border-b text-gray-500 border-gray-100 w-full flex flex-row gap-8 xl:gap-16">
          <Tab
            onClick={handleTabClick}
            value="VITRULUX"
            activeTab={activeTab}
          />
          <Tab onClick={handleTabClick} value="KEOSVET" activeTab={activeTab} />
        </div>
        <TabContent
          activeTab={activeTab}
          tab="VITRULUX"
          href="https://lighting.vitrulux.com/build/projects"
          downloadHref="/vitrulux.zip"
          projects={VITRULUX_PROJECTS}
        />
        <TabContent
          activeTab={activeTab}
          tab="KEOSVET"
          href="https://keosvet.ru/nashi-proekty/"
          downloadHref="/keo_svet.zip"
          projects={KEOSVET_PROJECTS}
        />
      </div>
    </Page>
  );
};

interface TabProps {
  onClick: (value: IProjects) => void;
  value: IProjects;
  activeTab: IProjects;
}

const Tab = ({ activeTab, value, onClick }: TabProps) => {
  return (
    <button
      className="relative pb-2 text-center text-[1.07rem] xl:text-xl"
      onClick={() => onClick(value)}
    >
      {activeTab === value ? (
        <>
          <div className="absolute bg-white h-[3px] w-full bottom-[-2px] rounded-sm left-0"></div>
          <div className="absolute bg-white h-[5px] w-full bottom-[-2px] left-0 blur"></div>
          <GoldenText>{value}</GoldenText>
        </>
      ) : (
        <>{value}</>
      )}
    </button>
  );
};

interface TabContentProps {
  activeTab: IProjects;
  tab: IProjects;
  href: string;
  downloadHref: string;
  projects: IProject[];
}

const TabContent = ({
  activeTab,
  tab,
  href,
  downloadHref,
  projects,
}: TabContentProps) => {
  const [firstImage, setFirstImage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setFirstImage((prev) => (prev < projects.length - 1 ? prev + 1 : prev)),
    onSwipedRight: () => setFirstImage((prev) => (prev > 0 ? prev - 1 : prev)),
  });

  const ITEMS_AMOUNT = isMobile ? 1 : 3;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1280);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = (index: number) => {
    setSelectedImage(index);
    setIsModalOpen(true);
  };

  if (activeTab !== tab) return null;
  return (
    <>
      {isModalOpen && (
        <ProjectModal
          projects={projects}
          activeTab={activeTab}
          currentProject={selectedImage}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
      <div {...handlers} className="flex flex-col xl:flex-row justify-between relative">
        {projects
          .slice(firstImage, firstImage + ITEMS_AMOUNT)
          .map((project) => (
            <Project
              key={project.imageId}
              project={project}
              activeTab={activeTab}
              onClick={() =>
                openModal(
                  projects.findIndex(
                    ({ imageId }) => project.imageId === imageId
                  )
                )
              }
            />
          ))}
      </div>
      <div className="flex flex-col gap-8 xl:flex-row-reverse items-center justify-center mt-2">
        <div className="flex relative gap-4 w-full justify-center xl:justify-end">
          <button
            className="w-8 h-8 bg-contain disabled:opacity-50 bg-[url(img/arrow-left.png)]"
            disabled={firstImage === 0}
            onClick={() => setFirstImage((prev) => prev - 1)}
          />
          <button
            className="w-8 h-8 bg-contain disabled:opacity-50 bg-[url(img/arrow-right.png)]"
            disabled={firstImage + ITEMS_AMOUNT === projects.length}
            onClick={() => setFirstImage((prev) => prev + 1)}
          />
        </div>
        <div className="w-full flex flex-col gap-4 xl:gap-8 xl:flex-row">
          <a
            className="h-full w-full md:w-64 justify-center flex xl:justify-normal"
            target="_blank"
            rel="noopener noreferrer"
            href={href}
          >
            <PrimaryButton text="ВСЕ ПРОЕКТЫ" />
          </a>
          <a
            className="h-full w-full md:w-64 justify-center flex xl:justify-normal"
            target="_blank"
            rel="noopener noreferrer"
            href={downloadHref}
          >
            <SecondaryButton value="СКАЧАТЬ КАТАЛОГ" />
          </a>
        </div>
      </div>
    </>
  );
};
