import {
  GoldFrame,
  GoldGrayFrame,
  PageWrapper,
  RequiredSign,
  SecondaryButton,
  Title,
} from "../Components";
import { useRef, useState } from "react";
import { sendEmail } from "../utils";
import { Link } from "react-router-dom";

export const Consultation = () => {
  const form = useRef(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendEmail(form.current!, "template_o9fpnzf").then(() => setSuccess(true));
  };

  if (success) {
    return (
      <PageWrapper>
        <div className="relative flex flex-col items-center text-xl mt-[30%] xl:mt-[10%]">
          <GoldFrame>
            <div className="flex flex-col items-center text-xl p-8 xl:p-24 gap-4">
              <div>Спасибо, обратный звонок заказан!</div>
              <div>Наш менеджер перезвонит вам в ближайшее время.</div>
              <Link
                className="mt-8 h-12 w-full xl:w-fit flex items-center justify-center text-xl text-center font-medium rounded-lg bg-gradient-to-br from-yellow-200 via-yellow-600 to-yellow-300 text-blackish py-2 px-4 hover:via-30%"
                to="/"
              >
                ГЛАВНАЯ СТРАНИЦА
              </Link>
            </div>
          </GoldFrame>
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="h-screen">
      <form
        ref={form}
        onSubmit={handleSubmit}
        className="relative max-w-2xl mx-auto text-sm xl:text-xl"
      >
        <Title defaultSize="4xl" title="ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ" />
        <div className="mb-4">
          <label htmlFor="name" className="block mb-1">
            Имя
            <RequiredSign />
          </label>
          <GoldGrayFrame>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Введите ваше имя"
              required
              className="bg-blackish focus:border-3 focus:border-gold-300 w-full px-3 py-2 rounded"
            />
          </GoldGrayFrame>
        </div>

        <div className="mb-8">
          <label htmlFor="phone" className="block mb-1">
            Номер телефона
            <RequiredSign />
          </label>
          <GoldGrayFrame>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Введите номер вашего телефона"
              required
              className="bg-blackish w-full px-3 py-2 rounded"
            />
          </GoldGrayFrame>
        </div>
        <button type="submit" className="w-full md:w-64">
          <SecondaryButton value={"ЗАКАЗАТЬ ЗВОНОК"} />
        </button>
      </form>
    </PageWrapper>
  );
};
