export type IProject = {
  imageId: string;
  name: string;
  location?: string;
};

type ProjectProps = { project: IProject, activeTab: string, onClick: () => void };

export const Project = ({
  project,
  activeTab,
  onClick
}: ProjectProps) => {
  return (
    <div className="text-sm xl:text-xl">
      <img
        key={project.imageId}
        className="w-full xl:w-[420px] xl:h-[320px] pointer-events-none xl:pointer-events-auto cursor-pointer"
        src={`img/${activeTab.toLowerCase()}/${project.imageId}.png`}
        alt=""
        onClick={onClick}
      />
      <div className="mt-3">{project.name}</div>
      {project.location && <div>{project.location}</div>}
    </div>
  );
};
