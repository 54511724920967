import { Route, Routes, Navigate } from "react-router-dom";
import { Navbar } from "./Navbar";
import {
  Company,
  Consultation,
  Contacts,
  Introduction,
  Partners,
  Projects,
  Request,
  Services,
} from "./Screens";

function App() {
  return (
    <div className="h-full flex flex-col justify-center items-center bg-blackish px-auto xl:px-auto">
      <Navbar />
      <Routes>
        <Route path="/о-компании" Component={Company} />
        <Route path="/контакты" Component={Contacts} />
        <Route path="/услуги" Component={Services} />
        <Route path="/проекты" Component={Projects} />
        <Route path="/партнеры" Component={Partners} />
        <Route path="/заявка" Component={Request} />
        <Route path="/консультация" Component={Consultation} />
        <Route path="/" Component={Introduction} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </div>
  );
}

export default App;
