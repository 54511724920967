import { useEffect, useState } from "react";
import { IProject } from "./Project";

type ProjectModalProps = {
  projects: IProject[];
  activeTab: string;
  currentProject: number;
  closeModal: () => void;
};

export const ProjectModal = ({
  projects,
  activeTab,
  currentProject,
  closeModal,
}: ProjectModalProps) => {
  const [_currentProject, setCurrentProject] = useState(currentProject);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [closeModal]);

  return (
    <div className="fixed h-screen w-screen inset-0 flex items-center justify-center bg-black bg-opacity-80 z-20">
      <button
        className="w-8 h-8 bg-contain bg-[url(img/cross.svg)] bg-opacity-100 absolute top-8 right-8"
        onClick={closeModal}
      />
      <button
        className="w-8 h-8 bg-contain disabled:opacity-50 bg-[url(img/arrow-left.png)]"
        disabled={_currentProject === 0}
        onClick={() => setCurrentProject((prev) => prev - 1)}
      />
      <div className="flex flex-col items-center justify-center h-5/6 mx-5">
        <img
          key={projects[_currentProject].imageId}
          className="w-full h-full rounded-3xl background-matching-image"
          src={`img/${activeTab.toLowerCase()}/${
            projects[_currentProject].imageId
          }-full.png`}
          alt=""
        />
        <div className="flex gap-5">
          <div className="mt-5">{projects[_currentProject].name}</div>
          {projects[_currentProject].location && (
            <div className="mt-5">{projects[_currentProject].location}</div>
          )}
        </div>
      </div>

      <button
        className="w-8 h-8 bg-contain disabled:opacity-50 bg-[url(img/arrow-right.png)]"
        disabled={_currentProject === projects.length - 1}
        onClick={() => setCurrentProject((prev) => prev + 1)}
      />
    </div>
  );
};
