import { ConsultationLink, Footer, RequestButton } from "../Components";

export const Introduction = () => {
  return (
    <>
      <section
        className={`w-full h-screen flex flex-col justify-between relative gap-8`}
      >
        <div className="h-full w-1/2 right-1/2 top-0 absolute mix-blend-color-dodge animate-fade-in bg-luminous-gradient-line" />
        <div className="h-full w-1/2 left-1/2 top-0 absolute mix-blend-color-dodge animate-fade-in bg-luminous-gradient-line flipped" />
        <div className="h-full w-1/2 right-1/2 top-0 absolute mix-blend-color-dodge animate-fade-out bg-gradient-line opacity-0" />
        <div className="h-full w-1/2 left-1/2 top-0 absolute mix-blend-color-dodge animate-fade-out bg-gradient-line opacity-0 flipped" />
        <div className="h-full w-full blur-3xl top-0 fixed gradient-test top-[-30%] left-[-45%] " />
        <h1 className="text-2xl xl:text-6xl h-[50%] flex items-end xl:items-end justify-center text-center animate-fade-in xl:pb-8 relative">
          ЛУЧШИЕ
          <br /> СВЕТОТЕХНИЧЕСКИЕ РЕШЕНИЯ
          <br /> ДЛЯ ВАШИХ ПРОЕКТОВ
        </h1>
        <div className="relative flex h-[50%] flex-col xl:flex-row items-center justify-normal xl:items-baseline xl:justify-center text-center gap-4 xl:gap-8 animate-fade-in pt-8 xl:pt-16">
          <RequestButton />
          <ConsultationLink />
        </div>
      </section>
      <Footer />
    </>
  );
};
