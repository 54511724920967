import { About } from "./About";
import { Advantages } from "./Advantages";

export const Company = () => {
  return (
    <>
      <About />
      <Advantages />
    </>
  );
};
