import {
  GoldFrame,
  GoldGrayFrame,
  PageWrapper,
  RequiredSign,
  Title,
} from "../Components";
import { useRef, useState } from "react";
import { sendEmail } from "../utils";
import { Link } from "react-router-dom";

export const Request = () => {
  const form = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!validateEmail((form.current as any).email.value)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    sendEmail(form.current!, "template_v2b7dlk").then(() => {
      setSuccess(true);
    });
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  if (success)
    return (
      <PageWrapper>
        <div className="flex flex-col items-center text-xl mt-[30%] xl:mt-[10%]">
          <GoldFrame>
            <div className="flex flex-col items-center text-xl p-8 xl:p-24 gap-4">
              <div>Спасибо, ваша заявка отправлена!</div>
              <div>Мы свяжемся с вами в ближайшее время.</div>
              <Link
                className="mt-8 h-12 w-full xl:w-fit flex items-center justify-center text-xl text-center font-medium rounded-lg bg-gradient-to-br from-yellow-200 via-yellow-600 to-yellow-300 text-blackish py-2 px-4 hover:via-30%"
                to="/"
              >
                ГЛАВНАЯ СТРАНИЦА
              </Link>
            </div>
          </GoldFrame>
        </div>
      </PageWrapper>
    );

  return (
    <PageWrapper className="h-screen">
      <form
        ref={form}
        onSubmit={handleSubmit}
        className="relative max-w-2xl text-sm xl:text-xl mx-auto"
      >
        <Title defaultSize="4xl" title="ОСТАВИТЬ ЗАЯВКУ" />
        <div className="mb-4">
          <label htmlFor="name" className="block mb-1">
            Имя
            <RequiredSign />
          </label>
          <GoldGrayFrame>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Введите ваше имя"
              required
              className="bg-blackish focus:border-3 focus:border-gold-300 w-full px-3 py-2 rounded"
            />
          </GoldGrayFrame>
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block mb-1">
            Номер телефона
            <RequiredSign />
          </label>
          <GoldGrayFrame>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Введите номер вашего телефона"
              required
              className="bg-blackish w-full px-3 py-2 rounded"
            />
          </GoldGrayFrame>
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">
            E-mail
            <RequiredSign />
          </label>
          <GoldGrayFrame>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Введите адрес электронной почты"
              required
              className="bg-blackish w-full px-3 py-2 rounded"
            />
          </GoldGrayFrame>

          {emailError && <p className="text-red-500 mt-1">{emailError}</p>}
        </div>

        <div className="mb-8">
          <label htmlFor="description" className="block mb-1">
            Описание задачи
            <RequiredSign />
          </label>
          <GoldGrayFrame>
            <textarea
              id="description"
              name="description"
              placeholder="Опишите вашу задачу"
              required
              className="bg-blackish w-full px-3 py-2 rounded"
            ></textarea>
          </GoldGrayFrame>
        </div>

        <button
          type="submit"
          className="h-12 w-full md:w-64 text-center text-xl font-medium rounded-lg bg-gradient-to-br from-yellow-200 via-yellow-600 to-yellow-300 text-blackish py-2 px-4 hover:via-30%"
        >
          ОТПРАВИТЬ ЗАЯВКУ
        </button>
      </form>
    </PageWrapper>
  );
};
