export interface TitleProps {
  title: string;
  defaultSize?: string;
}

export const Title = ({ title, defaultSize = "5xl" }: TitleProps) => {
  return (
    <div className={`relative pb-16 font-medium text-3xl sm:text-${defaultSize}`}>
      {title}
    </div>
  );
};
