import { Link, useMatch } from "react-router-dom";
import { GoldenText } from "./Components/GoldenText";
import { useEffect, useState } from "react";

export const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`z-10 fixed w-full top-0 left-0 flex justify-center py-6 transition bg-${
        isMenuOpen || isScrolled ? "blackish" : "transparent"
      } `}
    >
      <div
        className="w-full xl:w-[1350px] px-8 xl:px-auto flex justify-between"
      >
        <Link onClick={() => setIsMenuOpen(false)} to="/">
          <img src="/logo192.png" className="w-16 xl:w-[100px]" alt="logo" />
        </Link>
        <nav className="gap-16 items-center hidden xl:flex">
          <TabLink to="/о-компании">О компании</TabLink>
          <TabLink to="/услуги">Услуги </TabLink>
          <TabLink to="/проекты">Проекты</TabLink>
          <TabLink to="/партнеры">Партнеры</TabLink>
          <TabLink to="/контакты">Контакты</TabLink>
        </nav>
        <Hamburger isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </div>
    </header>
  );
};

const TabLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  const isActive = useMatch(encodeURI(to));
  return (
    <Link className="text-2xl xl:text-xl" to={to}>
      {isActive ? <GoldenText>{children}</GoldenText> : children}
    </Link>
  );
};

const Hamburger = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <nav className="xl:hidden">
      <div className="flex items-center justify-between px-4 py-3">
        <button className="focus:outline-none" onClick={toggleMenu}>
          <svg fill="none" className="w-6" viewBox="0 0 24 24">
            <defs>
              <linearGradient
                id="gold-gradient"
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(120)"
              >
                <stop stopColor="#BF7A00" stopOpacity="0.75" />
                <stop offset="1" stopColor="#FFE195" />
              </linearGradient>
            </defs>
            <path
              d="M0 0.333344H24V3.00001H0V0.333344ZM0 9.66668H24V12.3333H0V9.66668ZM0 19H24V21.6667H0V19Z"
              fill={isMenuOpen ? "url(#gold-gradient)" : "white"}
            />
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <div
          className="fixed inset-0 flex flex-col items-center mt-24 px-2 pt-8 pb-3 space-y-4 xl:px-3 bg-blackish gap-4"
          onClick={() => setIsMenuOpen(false)}
        >
          <TabLink to="/о-компании">О компании</TabLink>
          <TabLink to="/услуги">Услуги </TabLink>
          <TabLink to="/проекты">Проекты</TabLink>
          <TabLink to="/партнеры">Партнеры</TabLink>
          <TabLink to="/контакты">Контакты</TabLink>
        </div>
      )}
    </nav>
  );
};
