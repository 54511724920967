import { Link } from "react-router-dom";

export const RequestButton = () => {
  return (
    <Link
      to="/заявка"
      className="w-64 h-12 flex items-center justify-center text-center text-xl font-medium rounded-lg bg-gradient-to-br from-yellow-200 via-yellow-600 to-yellow-300 text-blackish py-2 px-4 hover:via-30%"
    >
      ОСТАВИТЬ ЗАЯВКУ
    </Link>
  );
};

export const PrimaryButton = ({onClick, text}: {text: string, onClick?: () => {}}) => {
  return <button className="w-full md:w-64 h-12 flex items-center justify-center text-center text-xl font-medium rounded-lg bg-gradient-to-br from-yellow-200 via-yellow-600 to-yellow-300 text-blackish py-2 px-4 hover:via-30%">
    {text}
  </button>
}
