import { ReactNode } from "react";
import { Page, PageWrapper } from "../Components";
import { BusinessСard } from "../Components/BusinessСard";
import { GoldenText } from "../Components/GoldenText";

export const Contacts = () => {
  return (
    <>
      <Page title="КОНТАКТЫ" className="h-full xl:h-screen" footer={false}>
        <div className="flex flex-col xl:flex-row gap-8 text-sm xl:text-xl">
          <div className="flex flex-col w-full gap-8 leading-loose">
            <GoldenText className="text-3xl">Реквизиты</GoldenText>
            OOO «ГК Русь» <br />
            ИНН: 2312311527 <br />
            КПП: 231201001 <br />
            ОГРН: 1222300037354 <br />
            Расчетный счет: 40702810826020013034 <br />
            Банк: ФИЛИАЛ "РОСТОВСКИЙ" АО "АЛЬФА-БАНК" <br />
            БИК: 46015207 <br />
            Корр. счет: 30101810500000000207
          </div>
          <div className="flex flex-col w-full gap-8 leading-8">
            <GoldenText className="text-3xl">Адрес</GoldenText>
            <ul>
              <BulletPoint icon="location">
                Фактический адрес: <br /> г.
                Краснодар, ул. Бородинская, д. 156/2, офис 405 (Бизнес-центр "Время")
              </BulletPoint>
              <BulletPoint icon="location">
                Почтовый адрес: <br />
                350088, а/я No2301, ООО "ГК Русь"
              </BulletPoint>
              <BulletPoint icon="mail">01@gkrus.pro</BulletPoint>
              <BulletPoint icon="phone">+7 (909) 70 07 000</BulletPoint>
            </ul>
          </div>
        </div>
      </Page>
      <PageWrapper className="h-full xl:h-full pt-8 xl:pt-0" footer={true}>
        <GoldenText className="text-3xl mb-8">Контактные лица</GoldenText>
        <div className="grid grid-rows-4 xl:grid-rows-1 gap-8 grid-flow-col pb-0 xl:pb-8">
          <BusinessСard
            title="Проект-менеджер"
            name="Дмитрий"
            number="+7 (928) 28 28 665"
            email="02@gkrus.pro"
          />
          <BusinessСard
            title="Проект-менеджер"
            name="Артур"
            number="+7 (928) 039 30 45"
            email="08@gkrus.pro"
          />
          <BusinessСard
            title="Проект-менеджер"
            name="Алексей"
            number="+7 (938) 43 26 269"
            email="07@gkrus.pro"
          />
          <BusinessСard
            title="Проект-менеджер"
            name="Сергей"
            number="+7 (918) 15 71 287"
            email="05@gkrus.pro"
          />
        </div>
        <div className="mt-16">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2820.762026041838!2d39.09826527663638!3d45.00945427107011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40f05a79f76a971b%3A0xfa133a468d91317!2z0YPQuy4g0JHQvtGA0L7QtNC40L3RgdC60LDRjywgMTU2INC60L7RgNC_0YPRgSAyLCDQmtGA0LDRgdC90L7QtNCw0YAsINCa0YDQsNGB0L3QvtC00LDRgNGB0LrQuNC5INC60YDQsNC5LCDQoNC-0YHRgdC40Y8sIDM1MDkxMA!5e0!3m2!1sru!2spl!4v1685473250423!5m2!1sru!2spl"
            width="100%"
            height="400"
            loading="lazy"
          ></iframe>
        </div>
      </PageWrapper>
    </>
  );
};

const BulletPoint = ({
  children,
  icon,
}: {
  children: ReactNode;
  icon: string;
}) => {
  return (
    <li className="flex mb-2">
      <img
        src={`img/icons/${icon}.svg`}
        alt="Bullet Point"
        className="mr-4 mt-2 w-4 h-4"
      />
      {children}
    </li>
  );
};
