import emailjs from "@emailjs/browser";

export const sendEmail = async (form: string | HTMLFormElement, templateId: string) => {
  emailjs
    .sendForm(
      'service_9eh0jmj',
      templateId,
      form,
      'Kq4doisM56Td7LOpm'
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
};
