interface BusinessСardProps {
  title: string;
  name: string;
  number: string;
  email: string;
}

export const BusinessСard = ({
  title,
  name,
  number,
  email,
}: BusinessСardProps) => {
  return (
    <ul className="flex flex-col w-full h-full gap-2 text-sm xl:text-xl">
      <div className="mb-4">{title}:</div>
      <li className="flex mb-2">
        <img
          src="img/icons/person.svg"
          alt="Bullet Point"
          className="mr-4 mt-1 w-4 h-4"
        />
        {name}
      </li>
      <li className="flex mb-2">
        <img
          src="img/icons/phone.svg"
          alt="Bullet Point"
          className="mr-4 mt-1 w-4 h-4"
        />
        {number} (Telegram/WhatsApp)
      </li>
      <li className="flex mb-2">
        <img
          src="img/icons/mail.svg"
          alt="Bullet Point"
          className="mr-4 mt-1 w-4 h-4"
        />
        {email}
      </li>
    </ul>
  );
};
