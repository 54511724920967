import { RequestButton, ConsultationLink, Title, PageWrapper, AbsoluteLeftGradient } from "../Components";

const services = [
  {
    text: "Разработка концепции освещения под любую задачу",
    point: "img/distored-sphere-gold-point.png",
  },
  {
    text: "Подбор современного энергоэффективного оборудования под требования проекта",
    point: "img/cube-gold-point.png",
  },
  {
    text: "Выполнение профессиональных светотехнических расчетов с расстановкой оборудования в DiaLux",
    point: "img/sphere-gold-point.png",
  },
  {
    text: "Подготовка спецификаций оборудования с указанием актуальной стоимости",
    point: "img/icosahedron-gold-point.png",
  },
  {
    text: "Разработка нескольких альтернативных вариантов решений с ТЭО для выбора оптимального",
    point: "img/polegon-gold-point.png",
  },
];

export const Services = () => {
  return (
    <PageWrapper>
      <AbsoluteLeftGradient />
      <div className="absolute bg-[url(img/services-pattern.png)] bg-contain bg-no-repeat bg-right-top top-28 xl:top-40 right-8 w-1/2 h-1/2 xl:h-3/5 xl:w-full" />
      <Title title="НАШИ УСЛУГИ" />
      <ul className="relative flex-col">
        {services.map((item, index) => (
          <li key={index} className="flex text-sm xl:text-xl mb-4 xl:mb-8">
            <img
              src={item.point}
              alt="Bullet Point"
              className="mr-2 mt-2 w-4 h-4"
            />
            {item.text}
          </li>
        ))}
      </ul>
      <div className="flex flex-col xl:flex-row relative items-center xl:items-normal xl:justify-left py-4 xl:py-12 gap-4 xl:gap-8">
        <RequestButton />
        <ConsultationLink />
      </div>
    </PageWrapper>
  );
};
