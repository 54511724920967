import { ReactNode } from "react";

export const GoldFrame = ({ children }: { children: ReactNode }) => {
  return (
    <div className="w-full h-full p-[1px] bg-gradient-to-br via-10% to-40% from-yellow-200 via-yellow-600">
      <div className="w-full h-full p-[1px] bg-gradient-to-tl via-10% to-40% from-yellow-200 via-yellow-600">
        <div className="w-full bg-blackish h-full">{children}</div>
      </div>
    </div>
  );
};

export const GoldGrayFrame = ({ children }: { children: ReactNode }) => {
  return (
    <div className="w-full p-[1px] rounded-md bg-gradient-to-r to-100% from-yellow-600 to-gray-600">
      <div className="w-full h-full bg-black rounded-md">{children}</div>
    </div>
  );
};
