import { GoldGrayFrame, Page } from "../../Components";

export const Advantages = () => {
  return (
    <Page title="НАШИ ПРЕИМУЩЕСТВА">
      <div className="flex flex-col xl:flex-row justify-between gap-4 xl:gap-8">
        <Advantage
          imgSrc="img/euro.png"
          description="Возможность заключения агентского договора"
        />
        <Advantage
          imgSrc="img/calculator.png"
          description="Бесплатная профессиональная помощь со светорасчетами любой сложности"
        />
        <Advantage
          imgSrc="img/lock.png"
          description="Полное понимание механизмов создания проекта"
        />
      </div>
    </Page>
  );
};

interface AdvantageProps {
  imgSrc: string;
  description: string;
}

const Advantage = ({ imgSrc, description }: AdvantageProps) => {
  return (
    <GoldGrayFrame>
      <div className="flex flex-col p-4 xl:p-8 gap-4">
        <img className="w-8 md:w-16" src={imgSrc} alt="advantage" />
        <p className="text-sm xl:text-xl text-white">{description}</p>
      </div>
    </GoldGrayFrame>
  );
};
