import { ReactNode } from "react";
import { Title, TitleProps } from "./Title";
import { Footer } from "./Footer";
import { AbsoluteLeftGradient } from ".";

interface PageProps extends TitleProps {
  children: ReactNode;
  footer?: boolean;
  className?: string;
}

export const Page = ({ children, title, footer, className }: PageProps) => {
  return (
    <PageWrapper footer={footer} className={className}>
      <AbsoluteLeftGradient />
      <Title title={title} />
      <div className="relative z-5 h-fit xl:h-full">{children}</div>
    </PageWrapper>
  );
};

export const PageWrapper = ({
  children,
  footer = true,
  className = "h-fit xl:h-screen",
}: {
  children: ReactNode;
  footer?: boolean;
  className?: string;
}) => {
  return (
    <>
      <section
        className={`w-full flex flex-col xl:w-[1350px] px-8 xl:px-auto justify-between relative pt-36 xl:pt-40 gap-8 text-2xl xl:text-xl ${className}`}
      >
        <div className="h-fit xl:h-full">{children}</div>
      </section>
      {footer && <Footer />}
    </>
  );
};
