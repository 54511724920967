import { ReactNode, useEffect, useRef, useState } from "react";
import { Page } from "../../Components";

const itemsAmount = 4;

export const About = () => {
  const [active, setActiveState] = useState(0);

  useEffect(() => {
    if (active === 4) return;
    const timeout = setTimeout(() => {
      setActiveState((prev) => (prev + 1) % 5);
    }, 4000);

    return () => clearTimeout(timeout);
  }, [active]);

  return (
    <Page title="О КОМПАНИИ" footer={false}>
      <ProgressBar amount={itemsAmount} active={active + 1} />
      <div className="flex flex-col gap-4 xl:gap-8 pt-4">
        <AboutDescription isActive={active === 4 || active === 0}>
          ООО ГК «Русь» – светотехническая компания, предлагающая лучшие
          светотехнические решения для Ваших проектов.
        </AboutDescription>
        <AboutDescription isActive={active === 4 || active === 1}>
          Специалисты ГК «Русь» готовы оказать помощь в проектировании освещения
          жилых, административных и производственных помещений,
          архитектурно-декоративном освещении зданий и памятников, освещении
          улиц, площадей и парков, промышленных предприятий и их территорий.
        </AboutDescription>
        <AboutDescription isActive={active === 4 || active === 2}>
          Коммерческий отдел ООО ГК «Русь» предлагает сотрудничество
          дизайн-студиям, проектным и строительным организациям, архитекторам и
          другим профессионалам светотехнического рынка из всех городов России и
          СНГ.
        </AboutDescription>
        <AboutDescription isActive={active === 4 || active === 3}>
          Компания ООО ГК «Русь» — надежный партнер в создании светотехнического
          решения любой сложности.
        </AboutDescription>
      </div>
    </Page>
  );
};

type OrderProps = { amount: number; active: number };

const ProgressBar = ({ amount, active }: OrderProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = useRef(null);
  const screenWidth = useRef(window.innerWidth);

  return (
    <>
      <div
        ref={ref}
        className="border-t relative border-white w-full justify-between opacity-40"
      ></div>
      <div className="w-full flex relative justify-between">
        {active <= 4 ? (
          <Light active={active} screenWidth={screenWidth.current} />
        ) : (
          <>
            <Light active={1} screenWidth={screenWidth.current} />
            <Light active={2} screenWidth={screenWidth.current} />
            <Light active={3} screenWidth={screenWidth.current} />
            <Light active={4} screenWidth={screenWidth.current} />
          </>
        )}
        {getNArray(amount).map((value) => {
          return (
            <OrdinalNumber
              key={value}
              value={value}
              isActive={value === active || active === 5}
            />
          );
        })}
      </div>
    </>
  );
};

type LightProps = {
  active: number;
  screenWidth: number;
};

const Light = ({ active, screenWidth }: LightProps) => (
  <div
    className="absolute transition-all duration-1000 w-[20%] xl:w-[10%] flex justify-center -top-[2px]"
    style={{
      left: `${(active - 1) * (screenWidth < 1024 ? 26.75 : 30)}%`,
    }}
  >
    <div className="absolute border-t-[3px] w-3/4 h-4" />
    <div
      className="bg-gradient-to-b w-full absolute h-16 xl:h-28 from-white-transparent to-[70%] to-transparent"
      style={{
        clipPath: "polygon(30% 0, 70% 0, 100% 70%, 0 70%)",
      }}
    />
  </div>
);
type OrdinalNumberProps = { value: number; isActive: boolean };

const OrdinalNumber = ({ value, isActive }: OrdinalNumberProps) => {
  return (
    <div className="flex items-center w-[20%] xl:w-[10%] justify-center">
      <div
        className={`py-4 p-4 h-16 xl:h-28 flex items-center justify-center transition-all duration-1000 ${
          isActive
            ? "text-3xl xl:text-5xl bg-gradient-to-r from-yellow-200 via-yellow-600 to-yellow-400 bg-clip-text text-transparent"
            : "opacity-40 text-md xl:text-xl"
        }`}
      >
        0{value}
      </div>
    </div>
  );
};

type AboutItemProps = { isActive: boolean; children?: ReactNode };

const AboutDescription = ({ isActive, children }: AboutItemProps) => {
  return (
    <p className={`text-sm xl:text-xl ${!isActive && "opacity-40"}`}>
      {children}
    </p>
  );
};

const getNArray = (length: number) => {
  return Array.from({ length }, (_, i) => i + 1);
};
